import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  UntypedFormArray,
} from "@angular/forms";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
// import { MatAccordion } from "@angular/material/expansion/accordion";
// import { MatAccordion } from "@angular/material";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { connectionInterestService } from "../service/connection.service";
import { ApiService } from "../service/api.service";
import { RegisterService } from "../service/register.service";
import { MatAccordion } from "@angular/material/expansion";

// Program Class Attend Type DATA
const programTypeApi = [
  {
    program_type_name: "Online Degree",
    class_type: "Attend classes online",
    program_type_icon: "assets/images/online.svg",
    program_value: "onlineDegree",
  },
  {
    program_type_name: "Residential degree",
    class_type: "Attend classes Lynchburg, VA",
    program_type_icon: "assets/images/residential.svg",
    program_value: "residentialDegree",
  },
];

@Component({
  selector: "app-academic-interestion",
  templateUrl: "./academic-interestion.component.html",
  animations: [
    trigger("height4sAnim", [
      state("true", style({ opacity: 1, height: "auto" })),
      state("void", style({ opacity: 0, height: 0  })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("btnStep3sAnim", [
      state("true", style({ "margin-left": 0, opacity: 0 })),
      state("void", style({ opacity: 1, "margin-left": -280, "z-index": 99 })),
      transition("* => *", [animate("0.3s")]),
    ]),
    trigger("height2sAnim", [
      state("true", style({ opacity: 1, height: "auto" })),
      state("void", style({ opacity: 0, height: 0 })),
      transition("* => *", [animate("0.2s")]),
    ]),
    trigger("balloonEffect", [
      state(
        "true",
        style({ backgroundColor: "green", transform: "scale(1.5)" })
      ),
      state("void", style({ backgroundColor: "red", transform: "scale(1)" })),
      transition("* => *", [animate("2s")]),
    ]),
  ],
})
export class AcademicInterestionComponent implements OnInit {
  countryList: any = "countries";
  academicDetails: UntypedFormGroup;
  programTypeEditView: boolean = false;
  programLevelEditView: boolean = false;
  personalInfoFormValid$: any;
  subscription: Subscription;
  programLevelData: any;
  programTypeData: any;
  selectedProgram: any;
  selectedDegreeTitle: any;
  academicSortDataShow: boolean;
  fildShowvalue: boolean[];
  dualEnrollmentCollegeDetails: UntypedFormArray;

  academicData: any = [];
  academicDegreeData: any = [];
  academicDegreeLevelData: any = [];
  academicPogramLevelData:any = [];

  isAcademicViewMode: boolean = false;

  public progress: number;
  public formObserver: Subscription;

  @Output() private academicProgramTypePass = new EventEmitter<any>();
  @Output() progressBarData = new EventEmitter<any>();
  @ViewChild("academicAccordion", { static: false })
  academicAccordion: MatAccordion;

  // remove duplicate items from an array
  removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
  }

  // set program Class Attend Type
  getProgramTypeApi() {
    this.programTypeData = programTypeApi;
  }

  // get filterd data from API by Class Attend Type
  public programType(value) {
    const programTypeDetail = value;
    let d: any=document.getElementById('degLevel');
    d.scrollIntoView({behavior:"smooth"});

    // get filterd degreeType data
    if (programTypeDetail.value === "onlineDegree") {
      this.academicDegreeData = this.academicData.filter(
        (degreeType: any) => degreeType.is_online === 1
      );
    }
    if (programTypeDetail.value === "residentialDegree") {
      this.academicDegreeData = this.academicData.filter(
        (degreeType: any) => degreeType.is_online === 0
      );
    }

    // get filterd degreeLevel data
    this.academicDegreeLevelData = this.academicDegreeData.map(
      (degreeLvel: any) => degreeLvel.degree_type
    );
    this.academicDegreeLevelData = this.removeDuplicates(
      this.academicDegreeLevelData
    );
    this.academicProgramTypePass.emit(programTypeDetail);
  }

  constructor(
    private academicDetailsBuilder: UntypedFormBuilder,
    private _connectionInterestService: connectionInterestService,
    private apiService: ApiService,
    private registerService: RegisterService
  ) {
    this.getProgramTypeApi();
    this.progress = 0;
  }
  ngOnInit() {
    this._connectionInterestService.FildShowValue.subscribe(
      (fildShowvalue) => (this.fildShowvalue = fildShowvalue)
    );
    this.academicDetails = this.academicDetailsBuilder.group({
      degreeType: new UntypedFormControl("", Validators.required),
      degreeLevel: new UntypedFormControl("", Validators.required),
      programList: new UntypedFormControl("",Validators.required),
    });

    this.formObserver = this.academicDetails.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.onFormChanged(this.academicDetails));

    // get API data from apiService Service
    this.apiService.getAcademicList().subscribe((data: object) => {
      this.academicData = data;
    });
  }
  onFormChanged(form: UntypedFormGroup): void {
    this.progress = this.calculateFormProgress(form);
    this.progressBarData.emit(this.progress);
  }

  calculateFormProgress(form: UntypedFormGroup): number {
    const controlCount = Object.keys(form.controls).length;
    let validCount = 0;
    for (const [key, value] of Object.entries(form.controls)) {
      if (value.value != "") validCount++;
    }
    return ((validCount * 0.34) / 45) * 100;
  }
  // // Init DualEnrollment
  // initializeDualEnrollment(): FormGroup {
  //   return this.academicDetailsBuilder.group({
  //     dualEnrollmentCollegeNameAttended: new FormControl(''),
  //     dualEnrollmentLastYearAttended: new FormControl(''),
  //     dualEnrollmentHighSchoolCountry: new FormControl(''),
  //     degreeLevelReceivedFromSchoolAbove: new FormControl(''),

  //   })
  // }

  // get enrollment field value
  dualEnrollmentCollegeDetailsValued: any;
  dualEnrolActive: Boolean = true;
  getdualEnrollmentCollegeDetails(getIndex: number) {
    this.dualEnrollmentCollegeDetailsValued = this.academicDetails.get(
      "dualEnrollmentCollegeDetails"
    );
    if ((this.dualEnrollmentCollegeDetailsValued.length = 3)) {
      this.dualEnrollmentCollegeDetails.removeAt(0);
      this.dualEnrolActive = !this.dualEnrolActive;
    } else {
      this.dualEnrolActive = true;
    }
  }

  // get enrollment field control
  get dualEnrollmentCollegeDetaisCValue() {
    return this.academicDetails.get(
      "dualEnrollmentCollegeDetails"
    ) as UntypedFormControl;
  }

  dualEnrollmentCollegeDetailsValue: any = null;
  addDualEnrollmentColleges(): void {
    this.dualEnrollmentCollegeDetailsValue =
      this.academicDetails.controls.dualEnrollmentCollegeDetails.value;
    this.dualEnrollmentCollegeDetails = this.academicDetails.get(
      "dualEnrollmentCollegeDetails"
    ) as UntypedFormArray;
    // this.dualEnrollmentCollegeDetails.push(this.initializeDualEnrollment());
    for (let i = 0; i < this.dualEnrollmentCollegeDetailsValue.length; i++) {}
  }

  // filter programs by searching
  filterProgramString = "";
  filterChanged(filterText: string) {
    this.filterProgramString = filterText;
    if (filterText) {
      this.academicAccordion.openAll();
    } else {
      this.academicAccordion.closeAll();
    }
  }

  // get programs from degreeTypeList
  getProgramsList(itemName) {
    return this.academicPogramLevelData
      .filter(
        (item) =>
          item.title
            .toLowerCase()
            .indexOf(this.filterProgramString.toLowerCase()) > -1
      )
      .filter((item) => item["careers"][0].title === itemName);
  }

  // filterd program List
  filterdDegreeTypeList = [];
  getProgramLevel(DegreeTitleValue) {
    let d: any =document.getElementById('degLevel');
    d.scrollIntoView({behavior:"smooth"});
    this.selectedDegreeTitle = DegreeTitleValue;
    this.academicPogramLevelData = this.academicDegreeData.filter(
      (item) => item.degree_type === this.selectedDegreeTitle
    );
    this.filterdDegreeTypeList = this.academicPogramLevelData.map(
      (item) => item.careers[0].title
    );
    this.filterdDegreeTypeList = this.removeDuplicates(
      this.filterdDegreeTypeList
    );
  }

  goDown(){
    window.scrollTo({ top: 120, left: 0, behavior: "smooth" });
  }
  // get selected program from degree type
  selectedDegreeTypeTitle: string;
  getSelecedProgram(selectedProgram: any) {
    let d: any=document.getElementById('degLevel');
    d.scrollIntoView({behavior:"smooth"});
    this.selectedProgram = selectedProgram;
    this.selectedDegreeTypeTitle = this.selectedProgram.careers[0].title;
  }

  // show Master based on the degree type slected
  showSchollDiploma() {
    if (
      this.selectedDegreeTitle === "Bachelor's Degree" ||
      this.selectedDegreeTitle === "Graduate Certificate" ||
      this.selectedDegreeTitle === "Undergraduate Certificate" ||
      this.selectedDegreeTitle === "Associate's Degree"
    ) {
      return true;
    } else {
      return false;
    }
  }

  // show Master based on the degree type slected
  showMasterFields() {
    if (this.selectedDegreeTitle === "Master's Degree") {
      return true;
    } else {
      return false;
    }
  }

  // show Doctorate based on the degree type slected
  showDoctorateFields() {
    if (this.selectedDegreeTitle === "Doctorate") {
      return true;
    } else {
      return false;
    }
  }

  // edit degree type
  editDegreeType() {
    this.updateFormStateOnEdit();

    // remove previous data from service
    this.registerService.updateData(this.academicFormData);
    this.isAcademicViewMode = false;
  }

  // edit degree level
  editDegreeLevel() {
    this.academicDetails.get("degreeLevel")?.reset();
    this.academicDetails.get("programList")?.reset();
    // when edit after Academic Form submitted
    this.updateFormStateOnEdit();

    // remove previous data from service
    this.updateFormData();
  }

  // edit selected program
  editSelectedProgram() {
    this.academicDetails.get("programList")?.reset();
    // when edit after Academic Form submitted
    this.updateFormStateOnEdit();

    // remove previous data from service
    this.updateFormData();
  }

  // remove enrollment field
  removeDualEnrollmentColleges(i: number) {
    this.dualEnrollmentCollegeDetails = this.academicDetails.get(
      "dualEnrollmentCollegeDetails"
    ) as UntypedFormArray;
    this.dualEnrollmentCollegeDetails.removeAt(i);
  }

  academicInterestFormData = { formValue: {} };

  // Submit Academic Form
  academicInterestSubmit() {
    if (this.academicDetails.valid) {
      this.updateFormStateOnSubmit();
      window.scrollTo({ top: 120, left: 0, behavior: "smooth" });

      // get form data
      this.updateFormData();
      this.academicInterestFormData = {
        formValue: this.academicDetails.value,
        // selectedItem: this.selectedProgram,
      };
      this.registerService.pushData(this.academicInterestFormData);
      this.isAcademicViewMode = true;
    } else {
      this._connectionInterestService.fldShowValueChange([
        true,
        false,
        false,
        false,
      ]);
      this.isAcademicViewMode = false;
    }
  }

  // update form data when edit form fields
  academicFormData;
  updateFormData() {
    this.academicFormData = this.academicDetails.value;
    this.registerService.updateData(this.academicInterestFormData);
  }

  // update form active state for other forms when editing current form
  updateFormStateOnEdit() {
    let isPersonalFormEditing: boolean = false;
    let isPreviousEducationFormEditing: boolean = false;
    isPersonalFormEditing = this._connectionInterestService.getValueChange()[2];
    isPreviousEducationFormEditing =
      this._connectionInterestService.getValueChange()[1];
    if (isPreviousEducationFormEditing && isPersonalFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        true,
        true,
        false,
      ]);
    } else if (isPersonalFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        false,
        true,
        false,
      ]);
    } else if (isPreviousEducationFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        true,
        false,
        false,
      ]);
    } else {
      this._connectionInterestService.fldShowValueChange([
        true,
        false,
        false,
        false,
      ]);
    }
  }

  // update form active state for other forms when sumiting current form
  updateFormStateOnSubmit() {
    let isPersonalFormEditing: boolean = false;
    this._connectionInterestService.fldShowValueChange([
        false,
        true,
        false,
        false,
    ]);

  }
}
