// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm_detail_header {
  margin-top: 24px;
}

.fld_step_002_wrp {
  margin-top: 32px;
}

.fld_col_50 .text_15 {
  font-weight: 500;
  margin-bottom: 18px;
}

.btn_001 {
  background-color: #102E68;
}

.input_phone_number {
  display: flex !important;
  align-items: center;
}
.input_phone_number .mat-mdc-form-field-error {
  position: absolute;
  display: flex;
  top: 48px !important;
  transition-duration: 0.3s;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/personal-information/personal-information.component.scss","webpack://./src/assets/style/_color_theme.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;AAAF;;AAEA;EACE,gBAAA;AACF;;AAEE;EACE,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACE,yBCVU;ADWZ;;AACA;EAEE,wBAAA;EACA,mBAAA;AACF;AAAE;EACE,kBAAA;EACA,aAAA;EACA,oBAAA;EACA,yBAAA;EACA,eAAA;AAEJ","sourcesContent":["@import '../../assets/style/color_theme';\n.sm_detail_header {\n  margin-top: 24px;\n}\n.fld_step_002_wrp {\n  margin-top: 32px;\n}\n.fld_col_50 {\n  .text_15 {\n    font-weight: 500;\n    margin-bottom: 18px;\n  }\n}\n.btn_001{\n  background-color: $color_002;\n}\n.input_phone_number {\n  \n  display: flex !important;\n  align-items: center;\n  .mat-mdc-form-field-error{    \n    position: absolute;\n    display: flex;\n    top: 48px !important;\n    transition-duration: 0.3s;\n    font-size: 13px;}\n}\n","//color theme\n$sectionSeparateColor: #e5e5e5;\n$mainBackground: #f7f8fc;\n$color_001: #345a7d;\n$color_002: #102E68;\n$color_003: #6d6f75;\n$color_004: #212121;\n$color_005: #c7c7c7;\n$color_006: rgba(210, 210, 210, 0.15);\n$color_007: #7f7f7f;\n$color_008: #7a7c82;\n$color_009: #f1faff;\n$color_010: #f8fafb;\n$color_011: #252525;\n$color_012: #dbdddf;\n$color_013: #558ec2;\n$color_014: #a5cde3;\n$color_015: rgb(255, 243, 221);\n$color_016: #94bfe1;\n$color_017:#F4FAFF;\n$color_018:#3C854D;\n$badge_color_001: #18cfbd;\n$badge_bg_001: #f3fcfb;\n$badge_color_002: #886cff;\n$badge_bg_002: #eae6fd;\n$badge_color_003: #ffb401;\n$badge_bg_003: #fff8e5;\n$badge_color_004: #ff4181;\n$badge_bg_004: #f8eff4;\n\n//font style\n$fontDefault: \"Inter\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
