import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from './side-bar/side-bar.component';
import {ProgressSpinnerMode, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatProgressSpinnerModule } from '@angular/material';



@NgModule({
  declarations: [SideBarComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    
  ],
  exports: [
    SideBarComponent,
  ]
})
export class SharedModule { }
