import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { connectionInterestService } from "../../service/connection.service";
import { ThemePalette } from "@angular/material/core";
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
  imports: [
    MatProgressSpinnerModule
  ]
})
export class SideBarComponent implements OnInit {
  isStepActive: any;
  fildShowvalue: boolean[];
  progressValue: number = 0;
  progressText: string = "application in progress";
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';

  constructor(private _connectionInterestService: connectionInterestService) {
    this._connectionInterestService.FildShowValue.subscribe(
      (messageFildShowValue) => {
        this.fildShowvalue = messageFildShowValue;
        if (this.fildShowvalue[0]) {
          this.isStepActive = {
            steps: {
              firstStep: true,
              secondStep: false,
              thirdStep: false,
              stepCompleted: false,
            },
          };
          this.progressValue = 0;
        } else if (this.fildShowvalue[1]) {
          this.isStepActive = {
            steps: {
              firstStep: false,
              secondStep: true,
              thirdStep: false,
              stepCompleted: false,
            },
          };
          this.progressValue = 4;
        } else if (this.fildShowvalue[2]) {
          this.isStepActive = {
            steps: {
              firstStep: false,
              secondStep: false,
              thirdStep: true,
              stepCompleted: false,
            },
          };
          this.progressValue = 58;
        } else if (this.fildShowvalue[3]) {
          this.isStepActive = {
            steps: {
              firstStep: false,
              secondStep: false,
              third: false,
              stepCompleted: true,
            },
          };
          this.progressValue = 98;
        } else {
          this.isStepActive = {
            steps: {
              firstStep: false,
              secondStep: false,
              third: false,
              stepCompleted: false,
            },
          };
          this.progressText = "application completed";
          this.progressValue = 100;
        }
      }
    );
  }

  ngOnInit() {}
  // private totalProgressValue: number;

  // @Input() set progressData(value: number) {
  //   this.totalProgressValue = value;
  //   this.progressValue = this.totalProgressValue;
  //   if (this.totalProgressValue >= 98) {
  //     this.progressValue = 98;
  //   }
  // }
}
